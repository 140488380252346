import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { LangEnum } from '../constant/lang-enum';

@Injectable({
  providedIn: 'root',
})
export class SharedDataService {
  lang = new BehaviorSubject<string>(LangEnum.EN);

  constructor() {}
}
