import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from '../app-material-module';

import { SharedRoutingModule } from './shared-routing.module';
import { SharedComponent } from './shared.component';
import { NavigateComponent } from './navigate/navigate.component';
import { FooterComponent } from './footer/footer.component';

@NgModule({
  declarations: [SharedComponent, NavigateComponent, FooterComponent],
  imports: [CommonModule, SharedRoutingModule, AppMaterialModule],
  exports: [
    SharedComponent,
    NavigateComponent,
    FooterComponent,
    AppMaterialModule,
  ],
})
export class SharedModule {}
