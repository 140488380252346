export enum IntroEnum {
  FEATURE = 'Features Implemented in this Project:',
  FEATURE_KR = '이 프로젝트가 포함하는 내용:',
  FEATURE1 = 'Language setting',
  FEATURE1_KR = '언어 설정',
  FEATURE2 = 'Lazy loading',
  FEATURE2_KR = 'Lazy loading',
  FEATURE3 = 'Hiding URL path',
  FEATURE3_KR = 'URL 경로 숨김',
  FEATURE4 = 'Responsive web page for all possible screen sizes including mobile',
  FEATURE4_KR = '(모바일 사이즈 포함하여) 가능한 모든 화면 크기에 대한 반응형 웹 페이지',
  FEATURE5 = 'Configuration of separate mobile and responsive desktop views',
  FEATURE5_KR = '모바일, 반응형 데스크톱 별도 화면 구성 (2가지 버전: 현재 info 페이지에만 화면 차이 적용 - 모바일 기기와 컴퓨터로 각 방문하여 비교 가능)',
  FEATURE6 = 'Automatic deployment using GitHub and Netlify',
  FEATURE6_KR = 'GitHub 과 Netlify 를 사용한 자동 배포',

  GUIDE = "Visit 'msugarcube.com' on a mobile device to check out the mobile view.",
  GUIDE_KR = "모바일 기기에서 'msugarcube.com' 를 방문하여 모바일 View 도 확인해보세요!",

  GUIDE_MO = "Visit 'msugarcube.com' on a desktop or laptop computer to checkout the responsive web view.",
  GUIDE_MO_KR = "데스크톱 또는 노트북 컴퓨터에서 'msugarcube.com' 를 방문하여 반응형 웹 View 를 확인해보세요!",

  SUB_GUIDE = 'The desktop version has been designed to be responsive and will display optimally even when the screen size is adjusted to that of a mobile device. Two distinct views have been created: the first for usage on all devices, featuring adjustable screen scaling, and the second, which is exclusively for mobile devices and features a unique display layout.',
  SUB_GUIDE_KR = '데스크톱 버전은 반응형으로 설계되어 화면 크기를 모바일 장치에 맞게 조정해도 최적으로 표시됩니다. 두 가지 View 가 제작되었습니다: 첫 번째는 모든 스크린 사이즈를 고려하여 화면 넓이에 따라 조절됩니다. 두 번째는 모바일 사이즈 전용으로 고유한 디스플레이 레이아웃을 제공합니다.',
  PURPOSE = 'Purpose of Making This Site',
  PURPOSE_KR = 'Purpose of Making This Site',

  PURPOSE_MAIN2 = 'To practice CSS and responsive design',
  PURPOSE_MAIN2_KR = 'CSS 와 반응형 디자인 연습',
  PURPOSE_MAIN3 = 'To try out GitHub & Netlify deployment setup',
  PURPOSE_MAIN3_KR = 'GitHub 과 연결하여 Netlify 자동 배포 시도',
  PURPOSE_MAIN4 = 'To create a burial place for my artworks and outdated stories',
  PURPOSE_MAIN4_KR = '내 작품과 나의 지나간 이야기를 묻을 공간 마련',
  VIDEO = 'Clip of January Effect',
  VIDEO_KR = '1월효과 (중 일부)',
  VIDEO_TITLE = 'Exhibition: Creative Mischief (2017)',
  VIDEO_TITLE_KR = '2017년 Creative Mischief 에서 전시',
}
