<div id="guide" class="glow">{{
  this.lang === LangEnum.EN ? IntroEnum.GUIDE_MO : IntroEnum.GUIDE_MO_KR
}}</div>

<div style="text-align: center; margin-top: 4em">
  <iframe
    src="https://giphy.com/embed/VekcnHOwOI5So"
    width="250"
    height="250"
    frameBorder="0"
  ></iframe>
</div>

<div class="implement-container">
  <div class="implement-title1">
    {{ this.lang === LangEnum.EN ? IntroEnum.FEATURE : IntroEnum.FEATURE_KR }}
  </div>
  <div class="pgx-target">
    <div class="number-circle"><span class="number-circle-txt"> 1 </span></div>
    <div class="target-txt">
      {{
        this.lang === LangEnum.EN ? IntroEnum.FEATURE1 : IntroEnum.FEATURE1_KR
      }}
    </div>
  </div>
  <div class="pgx-target">
    <div class="number-circle"><span class="number-circle-txt"> 2 </span></div>
    <div class="target-txt">
      {{
        this.lang === LangEnum.EN ? IntroEnum.FEATURE2 : IntroEnum.FEATURE2_KR
      }}
    </div>
  </div>
  <div class="pgx-target">
    <div class="number-circle"><span class="number-circle-txt"> 3 </span></div>
    <div class="target-txt">
      {{
        this.lang === LangEnum.EN ? IntroEnum.FEATURE3 : IntroEnum.FEATURE3_KR
      }}
    </div>
  </div>
  <div class="pgx-target">
    <div class="number-circle"><span class="number-circle-txt"> 4 </span></div>
    <div class="target-txt">
      {{
        this.lang === LangEnum.EN ? IntroEnum.FEATURE4 : IntroEnum.FEATURE4_KR
      }}
    </div>
  </div>
  <div class="pgx-target">
    <div class="number-circle"><span class="number-circle-txt"> 5 </span></div>
    <div class="target-txt">
      {{
        this.lang === LangEnum.EN ? IntroEnum.FEATURE5 : IntroEnum.FEATURE5_KR
      }}
    </div>
  </div>
  <div class="pgx-target">
    <div class="number-circle"><span class="number-circle-txt"> 6 </span></div>
    <div class="target-txt">
      {{
        this.lang === LangEnum.EN ? IntroEnum.FEATURE6 : IntroEnum.FEATURE6_KR
      }}
    </div>
  </div>
</div>

<div class="card-container">
  <mat-card id="first">
    <!-- <div class="implement-container">
            <div class="implement-title">
                {{ this.lang === LangEnum.EN ? IntroEnum.FEATURE : IntroEnum.FEATURE_KR }}
            </div>
            <ul class="implement-content">
                <li>{{ this.lang === LangEnum.EN ? IntroEnum.FEATURE1 : IntroEnum.FEATURE1_KR }}</li>
                <li>{{ this.lang === LangEnum.EN ? IntroEnum.FEATURE2 : IntroEnum.FEATURE2_KR }}</li>
                <li>{{ this.lang === LangEnum.EN ? IntroEnum.FEATURE3 : IntroEnum.FEATURE3_KR }}</li>
                <li>{{ this.lang === LangEnum.EN ? IntroEnum.FEATURE4 : IntroEnum.FEATURE4_KR }}</li>
                <li>{{ this.lang === LangEnum.EN ? IntroEnum.FEATURE5 : IntroEnum.FEATURE5_KR }}</li>
                <li>{{ this.lang === LangEnum.EN ? IntroEnum.FEATURE6 : IntroEnum.FEATURE6_KR }}</li>
            </ul>
        </div> -->

    <mat-card-header>
      <mat-card-title>
        <img src="../../../assets/rocket.png" width="30em" />
        {{
          this.lang === LangEnum.EN ? IntroEnum.PURPOSE : IntroEnum.PURPOSE_KR
        }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="main">
        <ol>
          <li>
            {{
              this.lang === LangEnum.EN
                ? IntroEnum.PURPOSE_MAIN2
                : IntroEnum.PURPOSE_MAIN2_KR
            }} </li
          ><br />
          <li>
            {{
              this.lang === LangEnum.EN
                ? IntroEnum.PURPOSE_MAIN3
                : IntroEnum.PURPOSE_MAIN3_KR
            }}

            <div class="setup-container">
              <div>
                <div class="step" style="margin-top: 20px">
                  Set up new Angular project
                </div>
                <div class="step-guide"
                  ><mat-icon class="dollar"> attach_money</mat-icon> ng new
                  project-name
                </div>
                <div class="step-guide"
                  ><mat-icon class="dollar"> attach_money</mat-icon> ng generate
                  module module-name --route module-name --module app.module
                </div>
                <div class="step-guide"
                  ><mat-icon class="dollar"> attach_money</mat-icon> ng g c
                  component-name
                </div>
              </div>

              <div>
                <div class="step"> Set up Netlify</div>
                <div class="step-guide"
                  ><mat-icon class="tool">build</mat-icon> Get key for private
                  Github repo deployment - save on Github repo setting
                </div>
                <div class="step-guide"
                  ><mat-icon class="face">face</mat-icon>
                  https://docs.netlify.com/configure-builds/repo-permissions-linking/#access-other-repositories-at-build </div
                ><br />
                <div class="step-guide"
                  ><mat-icon class="tool">build</mat-icon> Note: Set build
                  setting's publish directory to "dist/project-name"
                </div>
                <div class="step-guide"
                  ><mat-icon class="face"> sentiment_satisfied_alt</mat-icon>
                  Just "dist/", which is the default, causes page not found
                  error.
                </div>
              </div>

              <div>
                <div class="step"> Connect to Godaddy Domain</div>
                <div class="step-guide"
                  ><mat-icon mat-icon class="tool">build</mat-icon> I had my
                  domain, so..
                </div>
              </div>
            </div> </li
          ><br />
          <li>
            {{
              this.lang === LangEnum.EN
                ? IntroEnum.PURPOSE_MAIN4
                : IntroEnum.PURPOSE_MAIN4_KR
            }}
          </li>
        </ol>
        <div class="video-section">
          <div class="video-container">
            <video
              class="video"
              autoplay
              loop
              width="100%"
              height="100%"
              controls
            >
              <source
                src="../../assets/JanEffectPartial.mov"
                type="video/mp4"
              />
            </video>
          </div>
          <div class="video-title"
            >{{
              this.lang === LangEnum.EN ? IntroEnum.VIDEO : IntroEnum.VIDEO_KR
            }}
            -
            <a
              href="https://www.nyculturebeat.com/index.php?mid=Lounge2&npage=130&page=212&document_srl=3580067"
              target="_blank"
              >{{
                this.lang === LangEnum.EN
                  ? IntroEnum.VIDEO_TITLE
                  : IntroEnum.VIDEO_TITLE_KR
              }}</a
            ></div
          >
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
