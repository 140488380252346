<div class="social">
  <a href="https://github.com/m22kats" target="_blank"
    ><img
      src="../../../assets/social/GitHub-Mark-120px-plus.png"
      height="25em"
      width="25em"
  /></a>
  <a href="https://stackblitz.com/@m22kats/collections" target="_blank"
    ><img
      src="../../../assets/social/stacblitz.jpeg"
      height="25em"
      width="25em"
  /></a>
  <a href="https://codesandbox.io/u/m22kats" target="_blank"
    ><img
      src="../../../assets/social/codesandbox.png"
      height="25em"
      width="25em"
  /></a>
  <a href="https://medium.com/@m22kats" target="_blank"
    ><img
      src="../../../assets/social/icons8-medium.svg"
      height="25em"
      width="25em"
  /></a>
  <a href="https://m22kats.wixsite.com/website" target="_blank"
    ><img src="../../../assets/social/wix.svg" height="25em" width="25em"
  /></a>
  <a href="https://www.linkedin.com/in/marianne-kats/" target="_blank"
    ><img src="../../../assets/social/linkedIn.png" height="25em" width="25em"
  /></a>
</div>
<div> © 2023 M. S., All rights reserved. </div>
