import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { SharedDataService } from '../service/shared-data.service';
import { LangEnum } from '../constant/lang-enum';

class Menu {
  id: number;
  name: string;
  path: string;
  cssClass: string;
}

@Component({
  selector: 'app-navigate',
  templateUrl: './navigate.component.html',
  styleUrls: ['./navigate.component.scss'],
})
export class NavigateComponent implements OnInit, OnDestroy {
  lang: string = LangEnum.EN;
  LangEnum = LangEnum;
  langTxt: string = 'EN';

  menus: Array<Menu> = [
    // { id: 1, name: 'Info', path: '/intro', cssClass: 'selected' },
    // { id: 2, name: 'M Cube', path: '/maria', cssClass: '' },
    // { id: 3, name: '0', path: '/zero', cssClass: '' },
    // { id: 4, name: 'Entropy', path: '/entropy', cssClass: '' },
  ];
  constructor(
    private router: Router,
    private sharedDataSerivce: SharedDataService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}
  ngOnDestroy(): void {}

  onMenuClick(id: number) {
    this.menus.map((m) => (m.cssClass = ''));

    const selectedMenu = this.menus.find((m) => m.id === id);
    selectedMenu.cssClass = 'selected';
    this.router.navigate([selectedMenu.path], { skipLocationChange: true });
  }

  setLang(): void {
    this.lang = this.lang === LangEnum.EN ? LangEnum.KR : LangEnum.EN;
    this.sharedDataSerivce.lang.next(this.lang);
  }
  test(out?: string): void {
    this.langTxt = !!out
      ? this.lang
      : this.lang === LangEnum.EN
      ? LangEnum.KR
      : LangEnum.EN;
  }
}
