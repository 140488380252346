import { NgModule } from '@angular/core';
import {
  Routes,
  RouterModule,
  PreloadAllModules,
  Router,
} from '@angular/router';
import { IntroComponent } from './intro/intro.component';
import { IntroMobileComponent } from './intro/intro-mobile/intro-mobile.component';
import { AppStateService } from './shared/service/app-state.service';

// Mobile View
import { MariaMobileComponent } from './maria/maria-mobile/maria-mobile.component';
import { ZeroMobileComponent } from './zero/zero-mobile/zero-mobile.component';
import { EntropyMobileComponent } from './entropy/entropy-mobile/entropy-mobile.component';

const routes: Routes = [
  { path: '', redirectTo: 'zero', pathMatch: 'full' },
  { path: 'intro', component: IntroComponent },
  {
    path: 'entropy',
    loadChildren: () =>
      import('./entropy/entropy.module').then((m) => m.EntropyModule),
  },
  {
    path: 'zero',
    loadChildren: () => import('./zero/zero.module').then((m) => m.ZeroModule),
  },
  {
    path: 'maria',
    loadChildren: () =>
      import('./maria/maria.module').then((m) => m.MariaModule),
  },
  {
    path: 'shared',
    loadChildren: () =>
      import('./shared/shared.module').then((m) => m.SharedModule),
  },
  {
    path: '**',
    redirectTo: 'zero',
    pathMatch: 'full',
  },
];

// const mobile_routes: Routes = [
//   { path: "", redirectTo: "intro", pathMatch: "full" },
//   { path: 'intro', component: IntroMobileComponent },
//   { path: 'entropy', component: EntropyMobileComponent },
//   { path: 'zero', component: ZeroMobileComponent },
//   { path: 'maria', component: MariaMobileComponent },
//   { path: 'shared', loadChildren: () => import('./shared/shared.module').then(m => m.SharedModule) },
//   {
//     path: "**",
//     redirectTo: "intro",
//     pathMatch: "full",
//   }
// ];
const mobile_routes: Routes = [
  //tmp
  { path: '', redirectTo: 'zero', pathMatch: 'full' },
  { path: 'intro', component: IntroMobileComponent },
  {
    path: 'entropy',
    loadChildren: () =>
      import('./entropy/entropy.module').then((m) => m.EntropyModule),
  },
  {
    path: 'zero',
    loadChildren: () => import('./zero/zero.module').then((m) => m.ZeroModule),
  },
  {
    path: 'maria',
    loadChildren: () =>
      import('./maria/maria.module').then((m) => m.MariaModule),
  },
  {
    path: 'shared',
    loadChildren: () =>
      import('./shared/shared.module').then((m) => m.SharedModule),
  },
  {
    path: '**',
    redirectTo: 'zero',
    pathMatch: 'full',
  },
];

// @NgModule({
//   imports: [ RouterModule.forRoot(routes, { initialNavigation : false })],
//   exports: [RouterModule]
// })
// export class AppRoutingModule { }
@NgModule({
  // imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
  imports: [RouterModule.forRoot(routes, { initialNavigation: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {
  public constructor(
    private router: Router,
    private appStateService: AppStateService
  ) {
    if (appStateService.getIsMobileResolution()) {
      router.resetConfig(mobile_routes);
    }
  }
}
