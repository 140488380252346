<div id="guide" class="glow">{{
  this.lang === LangEnum.EN ? IntroEnum.GUIDE : IntroEnum.GUIDE_KR
}}</div>
<div id="sub-guide">{{
  this.lang === LangEnum.EN ? IntroEnum.SUB_GUIDE : IntroEnum.SUB_GUIDE_KR
}}</div>
<div class="card-container">
  <mat-card id="first">
    <mat-card-header>
      <mat-card-title>{{
        this.lang === LangEnum.EN ? IntroEnum.PURPOSE : IntroEnum.PURPOSE_KR
      }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="main">
        <ol>
          <li>
            {{
              this.lang === LangEnum.EN
                ? IntroEnum.PURPOSE_MAIN2
                : IntroEnum.PURPOSE_MAIN2_KR
            }} </li
          ><br />
          <li>
            {{
              this.lang === LangEnum.EN
                ? IntroEnum.PURPOSE_MAIN3
                : IntroEnum.PURPOSE_MAIN3_KR
            }} </li
          ><br />
          <li>
            {{
              this.lang === LangEnum.EN
                ? IntroEnum.PURPOSE_MAIN4
                : IntroEnum.PURPOSE_MAIN4_KR
            }}
          </li>
        </ol>
        <div class="video-section">
          <div class="video-container">
            <video
              class="video"
              autoplay
              loop
              width="100%"
              height="100%"
              controls
            >
              <source
                src="../../assets/JanEffectPartial.mov"
                type="video/mp4"
              />
            </video>
          </div>
          <div class="video-title"
            >{{
              this.lang === LangEnum.EN ? IntroEnum.VIDEO : IntroEnum.VIDEO_KR
            }}
            -
            <a
              href="https://www.nyculturebeat.com/index.php?mid=Lounge2&npage=130&page=212&document_srl=3580067"
              target="_blank"
              >{{
                this.lang === LangEnum.EN
                  ? IntroEnum.VIDEO_TITLE
                  : IntroEnum.VIDEO_TITLE_KR
              }}</a
            ></div
          >
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card id="second">
    <mat-card-header>
      <mat-card-title>Steps Taken to Build the Site</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <ol>
        <li>
          <div class="step" style="margin-top: 20px">
            Set up new Angular project
          </div>
          <div class="step-guide"
            ><mat-icon class="dollar"> attach_money</mat-icon> ng new
            project-name
          </div>
          <div class="step-guide"
            ><mat-icon class="dollar"> attach_money</mat-icon> ng generate
            module module-name --route module-name --module app.module
          </div>
          <div class="step-guide"
            ><mat-icon class="dollar"> attach_money</mat-icon> ng g c
            component-name
          </div>
        </li>

        <li>
          <div class="step"> Set up Netlify</div>
          <div class="step-guide"
            ><mat-icon class="tool">build</mat-icon> Get key for private Github
            repo deployment - save on Github repo setting
          </div>
          <div class="step-guide"
            ><mat-icon class="face">face</mat-icon>
            https://docs.netlify.com/configure-builds/repo-permissions-linking/#access-other-repositories-at-build </div
          ><br />
          <div class="step-guide"
            ><mat-icon class="tool">build</mat-icon> Note: Set build setting's
            publish directory to "dist/project-name"
          </div>
          <div class="step-guide"
            ><mat-icon class="face"> sentiment_satisfied_alt</mat-icon> Just
            "dist/", which is the default, causes page not found error.
          </div>
        </li>

        <li>
          <div class="step"> Connect to Godaddy Domain</div>
          <div class="step-guide"
            ><mat-icon mat-icon class="tool">build</mat-icon> I had my domain,
            so..
          </div>
        </li>
      </ol>
    </mat-card-content>
  </mat-card>
</div>
