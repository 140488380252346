import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedDataService } from './shared/service/shared-data.service';
import { LangEnum } from './shared/constant/lang-enum';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'm-sugarcube';

  constructor(
    private router: Router,
    private sharedDataSerivce: SharedDataService
  ) {}

  ngOnInit(): void {
    this.router.navigate(['/zero'], { skipLocationChange: true });
  }
}
