<div class="sticky container" style="justify-content: space-between">
  <div style="float: left; margin-left: 1.4%; align-items: left">
    <span
      *ngFor="let menu of menus"
      class="sticky-button glow"
      mat-flat-button
      [disableRipple]="true"
      [ngClass]="menu.cssClass"
      (click)="onMenuClick(menu.id)"
    >
      <span> {{ menu.name }} </span>
    </span>
  </div>
  <div
    div
    style="text-align: right; margin-right: 1.9%; margin-left: 1%"
    (click)="setLang()"
    (mouseover)="test()"
    (mouseout)="test('out')"
  >
    <div class="lang glow">
      <mat-icon>language</mat-icon><span>{{ langTxt }}</span></div
    >
  </div>
</div>
<br /><br /><br />
