import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { SharedDataService } from '../shared/service/shared-data.service';
import { IntroEnum } from './constant/intro-enum';
import { LangEnum } from '../shared/constant/lang-enum';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss'],
})
export class IntroComponent implements OnInit, OnDestroy {
  lang: string;
  IntroEnum = IntroEnum;
  LangEnum = LangEnum;

  private readonly destroy$ = new Subject();

  constructor(private sharedDataService: SharedDataService) {}

  ngOnInit(): void {
    this.sharedDataService.lang
      .pipe(takeUntil(this.destroy$))
      .subscribe((lang: string) => {
        this.lang = lang;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
